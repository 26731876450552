export const BALANCE_TRANSFER = 'BALANCE_TRANSFER';
export const CASHBACK_C2B = 'CASHBACK';
export const CASHBACK_PAYOUT = 'PAYOUT+';
export const DEPOSIT = 'DEPOSIT';
export const DEPOSIT_FEE = 'DEPOSIT_FEE';
export const EZW_PAYMENT_FEE = 'EZW_PAYMENT_FEE';
export const EZW_PAYOUT_FEE = 'EZW_PAYOUT_FEE';
export const EZW_REFUND_FEE = 'EZW_REFUND_FEE';
export const EZW_W2W_FEE = 'EZW_W2W_FEE';
export const LOYALTY_POINTS_CONVERSION = 'LOYALTY_POINTS_CONVERSION';
export const MANUAL_DEPOSIT = 'MANUAL_DEPOSIT';
export const MANUAL_REFUND_DEPOSIT = 'MANUAL_REFUND_DEPOSIT';
export const MANUAL_WITHDRAWAL = 'MANUAL_WITHDRAW';
export const MERCHANT_PAYMENT_FEE = 'MERCHANT_PAYMENT_FEE';
export const MERCHANT_PAYOUT_FEE = 'MERCHANT_PAYOUT_FEE';
export const MERCHANT_REFUND_FEE = 'MERCHANT_REFUND_FEE';
export const MERCHANT_W2W_FEE = 'MERCHANT_W2W_FEE';
export const PAYMENT = 'PAYMENT';
export const PAYOUT = 'PAYOUT';
export const REFUND = 'REFUND';
export const TRANSFER = 'TRANSFER';
export const WITHDRAWAL = 'WITHDRAWAL';
export const WITHDRAWAL_FEE = 'WITHDRAWAL_FEE';
